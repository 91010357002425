import React from 'react';

import Table from '../../UI/Table/Table';

function ContactsTable({ contacts, ...tableProps }) {
    const columns = [
        { title: 'Név', field: 'name' },
        {
            title: 'Email',
            field: 'email',
        },
        { title: 'Telefonszám', field: 'phone' },
        { title: 'Adószám', field: 'taxNumber' },
        { title: 'Számlázási cím', field: 'invoice' },
        { title: 'Dátum', field: 'date' },
        { title: 'Foglalkozás', field: 'jobs' },
        { title: 'Cég', field: 'company' },
        { title: 'Beosztás', field: 'companyPosition' },
        { title: 'Hírlevelél', field: 'newsletter', type: 'boolean' },
    ];
    return <Table title="Kontaktok" columns={columns} data={contacts} {...tableProps} />;
}
export default ContactsTable;
