import React from 'react';

import Table from '../../UI/Table/Table';

function UsersTable({ users, ...tableProps }) {
    const columns = [
        {
            title: 'Email',
            field: 'email',
        },
        { title: 'Név', field: 'name' },
        { title: 'Engedélyezett', field: 'enabled', type: 'boolean' },
    ];
    return <Table title="Felhasználók" columns={columns} data={users} {...tableProps} />;
}
export default UsersTable;
