import React from 'react';
import { useNavigate } from 'react-router-dom';

import Table from '../../UI/Table/Table';

function BuildingsTable({ buildings, title, ...tableProps }) {
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Épület neve',
            field: 'name',
        },
        { title: 'Épületazonosító', field: 'source' },
        { title: 'Főfelhasználó', field: 'mainUser' },
        { title: 'Cím', field: 'address' },
        {
            title: 'Főmodul típus',
            render: (rowData) => (rowData.mainModuleType === 'LAN' ? 'Lan' : 'Nective Hub'),
        },
        {
            title: 'Főmodul verzió',
            render: (rowData) =>
                rowData.mainModuleType === 'LAN' ? rowData?.versions?.lan : rowData.versions?.nectiveHub,
        },
    ];

    const handleRowClick = (_, rowData) => {
        navigate(`/building?source=${rowData.source}`);
    };

    return <Table onRowClick={handleRowClick} title={title} columns={columns} data={buildings} {...tableProps} />;
}
export default BuildingsTable;
