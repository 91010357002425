import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    Box,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Toolbar,
    Tooltip,
    Checkbox,
    Typography,
    FormControlLabel,
} from '@mui/material';
import { ChangeCircle, LockReset, Update } from '@mui/icons-material';

import { getBuildingBySource } from '../../store/filters';
import BuildingUsersTable from '../../components/Tables/BuildingUsersTable/BuildingUsersTable';
import ModuleTable from '../../components/Tables/ModuleTable/ModuleTable';
import {
    buildingUpdateStart,
    clearUpdateState,
    getCollectMetricsInfo,
    getUpdatesBySource,
    setCollectMetricsInfo,
} from '../../store/ducks/buildings';
import UpdatesTable from '../../components/Tables/UpdatesTable/UpdatesTable';
import {
    MODULE_TYPES,
    UPDATE_REQUEST,
    WEB_ADMIN,
    NECTIVE_HUB,
    UPDATE_TYPES_REVERSE,
    MODULE_TYPES_REVERSE,
    SMARTBRIDGE_ENERGRADE_KOM,
    SMART_BRIDGE,
    LIGHT2,
    LAN,
    LIGHT,
    ALL,
    BASIC,
    DISPLAY,
    SET_LIGHT_MODULE_SOFTWARE_TYPE,
    SUBSCRIPTION_TYPES,
} from '../../utils/constants';
import AlertDialog from '../../components/UI/AlertDialog/AlertDialog';
import { closeConnection, openConnection, sendMessage } from '../../store/ducks/ws';
import UpdateProgressDialog from '../../components/UI/UpdateProgressDialog/UpdateProgressDialog';
import { setToast } from '../../store/ducks/toast';
import DigitInputDialog from '../../components/UI/DigitInputDialog/DigitInputDialog';
import { enableNectiveHub } from '../../store/ducks/user';
import { isAdmin } from '../../utils/utilityFunctions';
import Terminal from '../../components/UI/Terminal/Terminal';
import LightModuleSoftwareChangeDialog from '../../components/UI/LightModuleSoftwareChangeDialog/LightModuleSoftwareChangeDialog';

export default function BuildingInfo() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [digitDialogOpen, setDigitDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [softwareTypeDialogOpen, setSoftwareTypeDialogOpen] = useState(false);
    const [selectedLightModuleID, setSelectedLightModuleID] = useState();
    const [currentUpdate, setCurrentUpdate] = useState();
    const collectMetrics = useSelector((state) => state.buildings.collectMetrics);
    const cycle = useSelector((state) => state.buildings.cycle);
    const [localCycle, setLocalCycle] = useState(cycle);
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const user = useSelector((state) => state.user.user);
    const currentBuilding = useSelector((state) =>
        getBuildingBySource(state, queryParams.get('source'), isAdmin(user))
    );
    const { updatesAvailable, loading, error, updateState, updateSuccess } = useSelector((state) => state.buildings);
    const hasVersions = !!currentBuilding?.versions;
    const isMainUser = user.role === 'ADMIN' || user.email === currentBuilding.mainUser;
    const { connected } = useSelector((state) => state.ws);
    const needNectiveHubEnable = currentBuilding?.mainModuleType === NECTIVE_HUB && !currentBuilding.enabled;

    const userList = useMemo(
        () =>
            currentBuilding.userEmails.map((email) => ({
                id: email,
                email,
                banned: currentBuilding.banList.includes(email),
                mainUser: currentBuilding.mainUser === email,
            })),

        [currentBuilding]
    );

    useEffect(() => {
        if (currentBuilding) {
            dispatch(getCollectMetricsInfo(currentBuilding.source));
        }
    }, [currentBuilding, dispatch]);

    useEffect(() => {
        setLocalCycle(cycle);
    }, [cycle]);

    useEffect(() => {
        if (isMainUser && !needNectiveHubEnable) {
            dispatch(getUpdatesBySource(currentBuilding.source));
        }
    }, [currentBuilding, dispatch, isMainUser, needNectiveHubEnable]);

    useEffect(() => {
        if (!loading && currentBuilding && isMainUser) {
            dispatch(openConnection());
            return () => dispatch(closeConnection());
        }
    }, [dispatch, loading, isMainUser, currentBuilding]);

    useEffect(() => {
        if (error) {
            setUpdateDialogOpen(false);
            dispatch(clearUpdateState());
        }
    }, [error, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            setUpdateDialogOpen(false);
            dispatch(setToast({ severity: 'success', open: true, message: 'Frissítés sikeresen befejeződött!' }));
            dispatch(clearUpdateState());
        }
    }, [updateSuccess, dispatch]);

    const sendUpdateRequest = async () => {
        dispatch(buildingUpdateStart());
        dispatch(
            sendMessage({
                source: WEB_ADMIN,
                destination: currentBuilding.source,
                messageType: UPDATE_REQUEST,
                updateType: UPDATE_TYPES_REVERSE[currentUpdate.updateType],
                moduleType: MODULE_TYPES_REVERSE[currentUpdate.moduleType],
                version: currentUpdate.version,
                isNectiveHub: MODULE_TYPES_REVERSE[currentUpdate.moduleType] === NECTIVE_HUB,
            })
        );
        setCurrentUpdate(null);
    };

    const sendLightModuleSoftwareTypeChange = async (softwareType) => {
        dispatch(
            sendMessage({
                source: WEB_ADMIN,
                messageType: SET_LIGHT_MODULE_SOFTWARE_TYPE,
                destination: currentBuilding.source,
                moduleID: selectedLightModuleID,
                softwareType,
                isNectiveHub: false,
            })
        );
    };

    const setCollectMetrics = async (value) => {
        dispatch(
            setCollectMetricsInfo({
                source: currentBuilding.source,
                collectMetricsInfo: { collectMetrics: value, cycle: +localCycle },
            })
        );
    };

    const modules = useMemo(() => {
        const modules = [];
        if (hasVersions && !loading) {
            Object.keys(currentBuilding.versions).forEach((key) => {
                if (key === 'light' && currentBuilding?.versions[key]) {
                    currentBuilding.versions[key].forEach((lm) => {
                        const lightUpdates = updatesAvailable.filter(
                            (u) => u.moduleType === LIGHT && (u.source === ALL || u.moduleIds.includes(lm.moduleID))
                        );
                        modules.push({
                            id: `${currentBuilding.source}${lm.moduleID}`,
                            source: lm.moduleID,
                            type: 'Light',
                            version: lm.version || lm.uVersion,
                            softwareType: lm.uVersion ? 'Egyedi' : 'Gyári',
                            update: lightUpdates.length,
                        });
                    });
                }
                if (key === 'basic' && currentBuilding?.versions[key]) {
                    currentBuilding.versions[key].forEach((lm) => {
                        const basicUpdates = updatesAvailable.filter(
                            (u) => u.moduleType === BASIC && (u.source === ALL || u.moduleIds.includes(lm.moduleID))
                        );
                        modules.push({
                            id: `${currentBuilding.source}${lm.moduleID}`,
                            source: lm.moduleID,
                            type: 'Basic',
                            version: lm.version || lm.uVersion,
                            softwareType: lm.uVersion ? 'Egyedi' : 'Gyári',
                            update: basicUpdates.length,
                        });
                    });
                }
                if (key === 'light2' && currentBuilding.versions[key]) {
                    currentBuilding.versions[key].forEach((lm) => {
                        const lightUpdates = updatesAvailable.filter(
                            (u) => u.moduleType === LIGHT2 && (u.source === 'ALL' || u.moduleIds.includes(lm.moduleID))
                        );
                        modules.push({
                            id: `${currentBuilding.source}${lm.moduleID}`,
                            source: lm.moduleID,
                            type: 'Light2',
                            version: lm.version || lm.uVersion,
                            softwareType: lm.uVersion ? 'Egyedi' : 'Gyári',
                            update: lightUpdates.length,
                        });
                    });
                }
                if (key === 'display' && currentBuilding.versions[key]) {
                    currentBuilding.versions[key].forEach((lm) => {
                        const displayUpdates = updatesAvailable.filter(
                            (u) => u.moduleType === DISPLAY && (u.source === 'ALL' || u.moduleIds.includes(lm.moduleID))
                        );
                        modules.push({
                            id: `${currentBuilding.source}${lm.moduleID}`,
                            source: lm.moduleID,
                            type: 'Display',
                            version: lm.version || lm.uVersion,
                            softwareType: lm.uVersion ? 'Egyedi' : 'Gyári',
                            update: displayUpdates.length,
                        });
                    });
                }
                if (key === 'lan' && currentBuilding.versions[key] !== '-') {
                    const lanUpdates = updatesAvailable.filter(
                        (u) => u.moduleType === LAN && (u.source === 'ALL' || u.source === currentBuilding.source)
                    );
                    modules.push({
                        id: `${currentBuilding.source}-lan`,
                        source: currentBuilding.source,
                        type: 'Lan',
                        version: currentBuilding.versions[key],
                        softwareType: 'Gyári',
                        update: lanUpdates.length,
                    });
                }
                if (key === 'nectiveHub' && currentBuilding.versions[key]) {
                    const nectiveHubUpdates = updatesAvailable.filter(
                        (u) =>
                            u.moduleType === NECTIVE_HUB && (u.source === 'ALL' || u.source === currentBuilding.source)
                    );
                    modules.push({
                        id: `${currentBuilding.source}-nectivehub`,
                        source: currentBuilding.source,
                        type: 'Nective Hub',
                        version: currentBuilding.versions[key],
                        softwareType: 'Gyári',
                        update: nectiveHubUpdates.length,
                    });
                }
                if (key === 'smartBridge' && currentBuilding.versions[key]) {
                    currentBuilding.versions[key].forEach((sb) => {
                        const smartBridgeUpdates = updatesAvailable.filter(
                            (u) => u.moduleType === SMART_BRIDGE && (u.source === 'ALL' || u.source === sb.source)
                        );
                        modules.push({
                            id: sb.source,
                            source: sb.source,
                            type: 'Smart Bridge',
                            version: sb.version,
                            softwareType: sb.type === 'UNIQUE' ? 'Egyedi' : 'Gyári',
                            update: smartBridgeUpdates.length,
                        });
                    });
                }
                if (key === 'smartBridgeEnergradeKom' && currentBuilding.versions[key]) {
                    currentBuilding.versions[key].forEach((sb) => {
                        const smartBridgeEnergradeUpdates = updatesAvailable.filter(
                            (u) =>
                                u.moduleType === SMARTBRIDGE_ENERGRADE_KOM &&
                                (u.source === 'ALL' || u.source === sb.source)
                        );
                        modules.push({
                            id: sb.source,
                            source: sb.source,
                            type: 'Smart Bridge Energrade Kom',
                            version: sb.version,
                            softwareType: sb.type === 'UNIQUE' ? 'Egyedi' : 'Gyári',
                            update: smartBridgeEnergradeUpdates.length,
                        });
                    });
                }
            });
        }
        return modules;
    }, [currentBuilding, hasVersions, loading, updatesAvailable]);

    return (
        <>
            <UpdateProgressDialog open={updateDialogOpen} value={updateState} />
            <AlertDialog
                title="Frissítés megerősítése"
                description="Biztos, hogy elindítod a frissítést? Később már nem tudod visszavonni!"
                open={dialogOpen}
                onClose={() => {
                    setCurrentUpdate(null);
                    setDialogOpen(false);
                }}
                onAgree={() => {
                    sendUpdateRequest();
                    setUpdateDialogOpen(true);
                }}
            />
            {isAdmin(user) && (
                <LightModuleSoftwareChangeDialog
                    title="Light modul szoftvermód váltás"
                    description="Biztos, hogy átváltod a szoftver módot a light modulon?"
                    open={softwareTypeDialogOpen}
                    onClose={() => {
                        setSoftwareTypeDialogOpen(false);
                    }}
                    onChoose={sendLightModuleSoftwareTypeChange}
                />
            )}
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />

                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <DigitInputDialog
                        open={digitDialogOpen}
                        onClose={() => setDigitDialogOpen(false)}
                        finalValue={(value) => {
                            dispatch(enableNectiveHub({ source: currentBuilding.source, key: value }));
                            setDigitDialogOpen(false);
                        }}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper
                                sx={{
                                    p: 4,
                                }}
                            >
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12} md={8} lg={4}>
                                            <TextField
                                                InputProps={{ readOnly: true }}
                                                fullWidth
                                                label="Épület neve"
                                                value={currentBuilding.name}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12} md={8} lg={4}>
                                            <TextField
                                                fullWidth
                                                InputProps={{ readOnly: true }}
                                                label="Épület címe"
                                                value={currentBuilding.address}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12} md={8} lg={4}>
                                            <TextField
                                                fullWidth
                                                color={needNectiveHubEnable ? 'warning' : ''}
                                                focused={needNectiveHubEnable}
                                                helperText={
                                                    currentBuilding.mainModuleType === NECTIVE_HUB &&
                                                    needNectiveHubEnable ? (
                                                        <Typography variant="div" color="#ed6c02">
                                                            Nective Hub még nem lett engedélyezve
                                                        </Typography>
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: needNectiveHubEnable && (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Nective hub engedélyezés"
                                                                onClick={() => setDigitDialogOpen(true)}
                                                                edge="end"
                                                            >
                                                                <Tooltip
                                                                    open={true}
                                                                    title="Nective hub engedélyezése"
                                                                    placement="top-start"
                                                                    arrow
                                                                >
                                                                    <LockReset color="primary" />
                                                                </Tooltip>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                label="Épület azonosító"
                                                value={currentBuilding.source}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12} md={8} lg={4}>
                                            <TextField
                                                fullWidth
                                                InputProps={{ readOnly: true }}
                                                label="Épület modul típus"
                                                value={MODULE_TYPES[currentBuilding.mainModuleType]}
                                            />
                                        </Grid>
                                    </Grid>
                                    {currentBuilding.subscriptionType && (
                                        <Grid container spacing={2} p={1}>
                                            <Grid item xs={12} md={8} lg={4}>
                                                <TextField
                                                    fullWidth
                                                    InputProps={{ readOnly: true }}
                                                    label="Épület előfizetés típusa"
                                                    value={SUBSCRIPTION_TYPES[currentBuilding.subscriptionType]}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                    {isAdmin(user) && (
                                        <>
                                            <Grid container spacing={2} p={1}>
                                                <Grid item xs={12} md={8} lg={4}>
                                                    <TextField
                                                        fullWidth
                                                        label="Metrika ciklus"
                                                        value={localCycle}
                                                        onChange={(event) => setLocalCycle(event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} p={1}>
                                                <Grid item xs={12} md={8} lg={4}>
                                                    <FormControlLabel
                                                        label="Metrika gyűjtés"
                                                        control={
                                                            <Checkbox
                                                                checked={collectMetrics}
                                                                onChange={(event) => {
                                                                    setCollectMetrics(event.target.checked);
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Box>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={8}>
                                        <BuildingUsersTable users={userList} />
                                    </Grid>
                                </Grid>
                                {!loading && isMainUser && connected && !needNectiveHubEnable && (
                                    <>
                                        <Grid container spacing={2} p={1}>
                                            <Grid item xs={12}>
                                                <ModuleTable
                                                    modules={modules}
                                                    actions={
                                                        !isAdmin(user)
                                                            ? []
                                                            : [
                                                                  (rowData) => ({
                                                                      icon: () => <ChangeCircle color="secondary" />,
                                                                      tooltip: 'Light modul szoftvermód váltás',
                                                                      onClick: () => {
                                                                          if (
                                                                              rowData.type.toUpperCase().includes(LIGHT)
                                                                          ) {
                                                                              setSelectedLightModuleID(rowData.source);
                                                                              setSoftwareTypeDialogOpen(true);
                                                                          }
                                                                      },
                                                                  }),
                                                              ]
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} p={1}>
                                            <Grid item xs={12}>
                                                <UpdatesTable
                                                    updates={updatesAvailable}
                                                    actions={[
                                                        (rowData) => ({
                                                            icon: () => <Update color="primary" />,
                                                            tooltip: 'Frissítés',
                                                            onClick: () => {
                                                                setCurrentUpdate(rowData);
                                                                setDialogOpen(true);
                                                            },
                                                        }),
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {!loading &&
                                    isAdmin(user) &&
                                    connected &&
                                    currentBuilding?.mainModuleType === 'NECTIVE_HUB' && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} p={1}>
                                                    <Grid item xs={12}>
                                                        <Terminal currentBuilding={currentBuilding} name={user.name} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
